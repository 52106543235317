









































































































































































import { PaginatedList } from "@/api/models/common/paginatedList"
import { EntrepriseShortStat } from "@/api/models/options/entreprises/entreprise"
import { Component, Vue } from "vue-property-decorator"
import { DroitsEnum } from "@/api/models/enums/droitsEnum";
import { EntreprisesByUser } from "@/api/models/statistiques/statistique";

@Component
export default class Home extends Vue {
  droitsEnum = DroitsEnum;

  lastEntreprises: PaginatedList<EntrepriseShortStat> | null = null

  listStatistiqueEntreprise: EntreprisesByUser[] = []

  async created() {
    await this.getLastEntreprises()
    await this.getEntrepriseByUser()
  }

  get hasAnyIndividualRight() {
    return (
      this.$can(this.droitsEnum.DOSSIER_EDP) ||
      this.$can(this.droitsEnum.DOSSIER_INFO_CONTRAT) ||
      this.$can(this.droitsEnum.DOSSIER_INFO_GENERAL) ||
      this.$can(this.droitsEnum.DOSSIER_INFO_SANTE) ||
      this.$can(this.droitsEnum.DOSSIER_PI) ||
      this.$can(this.droitsEnum.DOSSIER_NOTES)
    );
  }

  async getLastEntreprises(){
    await this.$http.ressifnet.entreprises.getLasts()
      .then((res: PaginatedList<EntrepriseShortStat>) => {
        this.lastEntreprises = res
      })
  }

  async getEntrepriseByUser(){
    await this.$http.ressifnet.statistiques.entreprisesByUser()
      .then((res : any) => {
        this.listStatistiqueEntreprise = res
      })
  }
}
